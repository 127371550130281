/** profile/projects.html */

.projectlist-actions {
  a {
    margin: 0;
  }
}

.header-wrapper {
  .projectlist-actions {
    text-align: right;
  }
}

.project-cards {
  .project-card {
    background-color: $white;
  }
}

;@import "sass-embedded-legacy-load-done:586";