/** auth/signup_account.html */

.signup-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 24px;


  ul#account_type {
    list-style-type: none;
  }

  .submit-button {
    margin-top: 1rem;
  }

  .academic-signup-warning {
    display: none;
  }

  .academic-signup-warning.is-visible {
    display: block;
  }
}




/** auth/signup_steps.html */

.steps {
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  width: 100%;

  //max-width: 1200px;
  margin: 16px auto;

}

.steps-item {
  display: inline-flex;
  overflow: hidden;
  flex: 1 1;
  vertical-align: top;
  margin-right: 16px;
  white-space: nowrap;
  min-width: 32px;


  .steps-item-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    transition: background-color .3s, border-color .3s;

    margin-right: 8px;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .25);
  }

  .steps-item-content {
    display: inline-block;
    vertical-align: top;


    .steps-item-title {
      position: relative;
      display: inline-block;
      padding-right: 16px;
      color: rgba(0, 0, 0, .65);
      font-size: 16px;
      line-height: 32px;

      font-weight: 500;

      &:after {
        position: absolute;
        top: 16px;
        left: 100%;
        display: block;
        width: 9999px;
        height: 1px;
        content: "";
        background-color: $medium-gray;
      }
    }
  }

  &:last-of-type {
    flex: none;

    .steps-item-content {
      .steps-item-title:after {
        display: none;
        width: 0;
      }
    }
  }

  @include breakpoint(medium down) {
    flex-direction: column;
    align-items: center;


  }

  @include breakpoint(small down) {
    flex-direction: row;

    .steps-item-icon {
      position: relative;

      &:after {
        margin-left: 8px;
        position: absolute;
        top: 16px;
        left: 100%;
        display: block;
        width: 9999px;
        height: 1px;
        content: "";
        background-color: $medium-gray;
      }
    }

    .steps-item-content {
      .steps-item-title {
        display: none;
      }
    }
  }

  &.steps-item-finish {
    .steps-item-icon {
      color: get-color(primary);
      border: 1px solid get-color(primary);
    }
  }

  &.steps-item-progress {
    .steps-item-icon {
      background: get-color(primary);
      color: color-pick-contrast(get-color(primary));
      border: 1px solid color-pick-contrast(get-color(primary));
    }
  }
}

.steps-create-account {
  max-width: 1200px;
  margin-bottom: 20px;
}

/** auth/signup_remoteuser_incomplete.html */

.signup-userdata-incomplete-wrapper{

  max-width: 680px;
  margin: 1em auto;
}

/** auth/signup_terms.html */

.signup-tos-wrapper {
  max-width: 680px;
  margin: 1em auto;


  .signup-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    [type='checkbox'] {
      margin: 1rem 0;
    }
  }
}

/** auth/signup_project.html */
.signup-project-wrapper {
  max-width: 680px;
  margin: 0 auto;
}
;@import "sass-embedded-legacy-load-done:555";