//  Settings for the imec theme
//  ---------------------------
@import 'colors';
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Card
//  15. Close Button
//  16. Drilldown
//  17. Dropdown
//  18. Dropdown Menu
//  19. Flexbox Utilities
//  20. Forms
//  21. Label
//  22. Media Object
//  23. Menu
//  24. Meter
//  25. Off-canvas
//  26. Orbit
//  27. Pagination
//  28. Progress Bar
//  29. Prototype Arrow
//  30. Prototype Border-Box
//  31. Prototype Border-None
//  32. Prototype Bordered
//  33. Prototype Display
//  34. Prototype Font-Styling
//  35. Prototype List-Style-Type
//  36. Prototype Overflow
//  37. Prototype Position
//  38. Prototype Rounded
//  39. Prototype Separator
//  40. Prototype Shadow
//  41. Prototype Sizing
//  42. Prototype Spacing
//  43. Prototype Text-Decoration
//  44. Prototype Text-Transformation
//  45. Prototype Text-Utilities
//  46. Responsive Embed
//  47. Reveal
//  48. Slider
//  49. Switch
//  50. Table
//  51. Tabs
//  52. Thumbnail
//  53. Title Bar
//  54. Tooltip
//  55. Top Bar
//  56. Xy Grid
@import '~foundation-sites/scss/util/util';
// 1. Global
// ---------
@import '~typeface-montserrat/index.css';

//white theme colors
$white-bg: $gray-3; //#f1f1f1;

//custom colors
$f4f-orange: #dd9421;
$f4f-red: #b5361c;

$theme-color-primary: $f4f-red;
$theme-color-secondary: $f4f-orange;

$global-font-size: 100%;
$global-width: rem-calc(1200);
$global-lineheight: 1.5;
$foundation-palette: (
        primary: $f4f-red, //$red-6,//#1779ba,
        update: $magenta-6, //,#767676,
        secondary: $gray-7, //,#767676,
        success: $green-6, //#3adb76,
        warning: $gold-6, //#ffae00,
        alert: $volcano-7, //#cc4b37,
);
$light-gray: $gray-3; //#e6e6e6;
$medium-gray: $gray-5; //#cacaca;
$dark-gray: $gray-8; //#8a8a8a;
$black: $gray-9; //#0a0a0a;
$white: $gray-1; //#fefefe;
$body-background: $white-bg; // $white;
$body-font-color: $black;
$body-font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;


//colors from https://palx.jxnblk.com/b5361c

$loggedin-navbar-link-hover-background-color: #db9d91;
$loggedin-navbar-link-active-background-color: #cd7665;

$loggedin-navbar-admin-color: #9bb51c;
$loggedin-navbar-admin-hover-background-color: #8ca319;
$loggedin-navbar-admin-active-background-color: #b1c54e;

@include add-foundation-colors;

;@import "sass-embedded-legacy-load-done:0";